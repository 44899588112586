$color: #ffffff80;

.completed {
  color: #7400bf;
}

.ongoing {
  color: #e90e7c;
}

.upcoming {
  color: #2ed65d;
}

.yettostart {
  color: #2ed65d;
}

.greetings {
  color: var(--Text-color);
  font-size: 2em;
  font-weight: 500;
  padding: 2%;
}

.full_width_container {
  width: 100% !important;
  padding-right: 2% !important;
}

.dashcard {
  width: 100%;
  border-radius: 15px;
  display: flex;
  min-height: 180px;
  cursor: pointer;
  position: relative;
  // margin: 10px;
  overflow: hidden;
}

.Dashboard_card_title {
  position: absolute;
  top: 10px;
  left: 15px;
  // left: 30px;
}

.card_content {
  position: absolute;
  bottom: 0px;
  width: 100%;
}

.align_card_text {
  display: inline-flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
}

.icon_main_div {
  width: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 0px 16px 22px;
}

.number_count {
  width: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 18px;
}

.table_div table thead th:last-child {
  text-align: left !important;
}

.card-items {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
}

.card-1 {
  background: linear-gradient(to bottom right, #7500bf, #742afc) !important;
  overflow: hidden;
  justify-content: flex-end;
  align-items: flex-start;
}

.card-2 {
  background: linear-gradient(to bottom right, #fc8600, #ff5a5a) !important; 
  justify-content: flex-end;
  align-items: flex-start;
}

.card-3 {
  background: linear-gradient(to bottom right, #1C74BC, rgb(0, 42, 77)) !important;
  justify-content: flex-end;
  align-items: flex-start;
}

.card-0 {
  background: linear-gradient(to bottom right, #e90b7e, #ff5a5a) !important;
  justify-content: flex-end;
  align-items: flex-start;
}

.card-4 {
  background: linear-gradient(to bottom right, #001314, #b25be5) !important;
  justify-content: flex-end;
  align-items: flex-start;
}

.card-5 {
  background: linear-gradient(to bottom right, #ffba00, #ffd93b) !important;
}

.card-title {
  font-size: 1.5em;
  color: white;
  font-weight: 400;
  // padding: 12% 15%;
  white-space: nowrap;
}

.dashcard img {
  // padding: 15% 15% 30% 15%;
  width: 100%;
}

.count {
  color: white;
  font-size: 3em;
  font-weight: 600;
}

.dashboard_div {
  border-bottom: 2px dashed lightgray;
  padding-bottom: 10px;
}

.init_status {
  color: var(--Warning-color);
}

.highlight:hover {
  color: red;
  cursor: pointer;
}

.circle-ripple {
  background-color: #fff;
  width: 2px;
  height: 2px;
  border-radius: 50%;
  animation: ripple 5s linear infinite;
}

@keyframes ripple {
  0% {
    box-shadow: 0 0 0 0 rgba($color, 0.15), 0 0 0 1em rgba($color, 0.15),
      0 0 0 3em rgba($color, 0.15), 0 0 0 5em rgba($color, 0.15),
      0 0 0 8em rgba($color, 0.15), 0 0 0 11em rgba($color, 0.15);
  }

  100% {
    box-shadow: 0 0 0 1em rgba($color, 0.15), 0 0 0 3em rgba($color, 0.15),
      0 0 0 5em rgba($color, 0.15), 0 0 0 8em rgba($color, 0.15),
      0 0 0 11em rgba($color, 0.15), 0 0 0 15em rgba($color, 0);
  }
}

.dashboard-card-wrapper {
  display: flex;
  column-gap: 1rem;
}

.icon_image_size {
  width: 4rem;
  filter: brightness(0) invert(1);
}

.dashboard-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 3rem;
}

.client-card {
  display: flex;
  margin-top: 20px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  row-gap: 1rem;
}

.client-card-img {
  width: 5rem;
  filter: brightness(0) invert(1);
}
.cursor_default {
  cursor: auto;
}
.check-gap {
  display: table-row;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.check-gap .custom_labelbox {
  display: table-cell;
  width: 33.33%; 
  vertical-align: top;
  padding: 5px;
}
.check-gap .custom_labelbox .label-read-only {
  font-size: 1rem;
  color: var(--Warning-color);
}
.clients-box {
  display: table;
  // min-width: 400px;
  width: 100%;
  border-spacing: 10px;
}
.labelbox-row {
  display: table-cell;
  width: 50%;
}
.client-box-paper {
  padding: 8px 0px;
}
.register-modal .MuiPaper-root {
  min-width: 75vw;
}
.client-header {
  display: table-header-group;
  background: aliceblue;
}
.client-header div {
  display: table-cell;
  width: 33.33%;
  vertical-align: top;
  padding: 5px;
  color: #000;
  font-size: 1rem;
  font-weight: 600;
}
.client-table-wrapper {
  max-height: 60vh;
  overflow: auto;
  margin-bottom: 1rem;
  min-height: 40vh;
}
